const { default: config } = require("config/config");
const constants = require("./constants");

const FREE_PLAN = "FREE_PLAN";
const IRON_PLAN = "IRON_PLAN";
const CONCRETE_PLAN = "CONCRETE_PLAN";
const CUSTOM_PLAN_NEHA = "CUSTOM_PLAN_NEHA";

const featureDefaults = {
  sslMonitoring: false,
  httpMethods: false,
  okStatusRange: false,
  httpHeaders: false,
  httpBody: false,
  statusPages: false,
  statusPagesCustomDomain: false,
};

const quotaDefaults = {
  monitors: 20,
  minMonitoringInterval: 300,
};

function planConfig({
  id,
  name,
  features,
  quotas,
  price = 0,
  currency = "EUR",
  currencySymbol = "€",
  period = "month",
  productId,
  defaultPriceId,
}) {
  return {
    id,
    name: name || "Generic Plan",
    price,
    currency,
    currencySymbol,
    period,
    features: {
      ...featureDefaults,
      ...(features || {}),
    },
    quotas: {
      ...quotaDefaults,
      ...(quotas || {}),
    },
    productId,
    defaultPriceId,
  };
}

const plansData = {
  plans: {
    FREE_PLAN,
    IRON_PLAN,
    CONCRETE_PLAN,
  },
  prices: {
    price_1NBfD0FHQhRwh4QLUFf9PD3o: {
      id: "price_1NBfD0FHQhRwh4QLUFf9PD3o",
      prod: "prod_NxaNC1rXjP3rBb",
      period: "month",
      currencies: [
        {
          code: "eur",
          symbol: "€",
          price: 6,
        },
        {
          code: "usd",
          symbol: "$",
          price: 6.5,
        },
      ],
    },
  },
  products: {
    prod_NxaNC1rXjP3rBb: IRON_PLAN,
  },
  stripePriceMap: {
    price_1LT0MqFHQhRwh4QL2aqWmr1H: CUSTOM_PLAN_NEHA,
  },
  [FREE_PLAN]: planConfig({
    id: FREE_PLAN,
    name: "Free Plan",
    features: {
      statusPages: true,
    },
  }),
  [IRON_PLAN]: planConfig({
    id: IRON_PLAN,
    name: "Iron Plan",
    productId: "prod_NxaNC1rXjP3rBb",
    defaultPriceId: constants[config.env].PRO_PLAN_DEFAULT_PRICE_ID,
    price: 6,
    features: {
      sslMonitoring: true,
      httpMethods: true,
      okStatusRange: true,
      httpHeaders: true,
      httpBody: true,
      statusPages: true,
      statusPagesCustomDomain: true,
    },
    quotas: {
      monitors: 50,
      minMonitoringInterval: 60,
    },
    currency: "usd",
    currencySymbol: "$",
  }),
  [CONCRETE_PLAN]: planConfig({
    id: CONCRETE_PLAN,
    name: "Concrete Plan",
    productId: "prod_NxaNC1rXjP3rBb",
    defaultPriceId: constants[config.env].PRO_PLAN_DEFAULT_PRICE_ID,
    price: 29,
    features: {
      sslMonitoring: true,
      httpMethods: true,
      okStatusRange: true,
      httpHeaders: true,
      httpBody: true,
      statusPages: true,
      statusPagesCustomDomain: true,
    },
    quotas: {
      monitors: 1000,
      minMonitoringInterval: 1800,
    },
    currency: "usd",
    currencySymbol: "$",
  }),
  [CUSTOM_PLAN_NEHA]: planConfig({
    id: CUSTOM_PLAN_NEHA,
    name: "Custom Plan",
    price: 2373.56,
    currency: "INR",
    currencySymbol: "₹",
    quotas: {
      minMonitoringInterval: 1800,
      monitors: 1076,
    },
  }),
};

module.exports = plansData;
