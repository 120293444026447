import { createCheckoutSessionApi } from "api/payments.api";
import config from "config/config";

let stripe;

export default function getStripe() {
  if (stripe) return stripe;
  stripe = global.Stripe(config.stripePublicKey);
  return stripe;
}

export async function subscribe(priceId, accessToken) {
  const res = await createCheckoutSessionApi({
    priceId,
    accessToken,
  });
  const stripe = getStripe();
  const { sessionId } = res.data;
  return () => stripe.redirectToCheckout({ sessionId });
}
