const staging = {
  PRO_PLAN_DEFAULT_PRICE_ID: "price_1NBfD0FHQhRwh4QLUFf9PD3o",
};

const constants = {
  production: {
    PRO_PLAN_DEFAULT_PRICE_ID: "price_1NBtncFHQhRwh4QL5Zn3YVPz",
  },
  staging,
  development: staging,
  pricingPath: "/pricing",
};

module.exports = constants;
