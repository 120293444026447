import axios from "axios";
import config from "./api.config";

export function createCheckoutSessionApi({ priceId, accessToken }) {
  return axios.post(
    `${config.API_URL}/payments/createCheckoutSession`,
    {
      priceId,
    },
    {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }
  );
}

export function customerPortalApi(returnPath) {
  return axios.get(`${config.API_URL}/payments/customerPortal`, {
    params: {
      returnPath,
    },
  });
}
