import plans from "commons/plans";
import MarvyPricingTable from "./MarvyPricingTable";
import humanizeDuration from "humanize-duration";
import { MT } from "./MarvyTypography";
import Link from "next/dist/client/link";

export default function MarvyPricingMay23() {
  return (
    <>
      <MarvyPricingTable
        plans={[plans.FREE_PLAN, plans.IRON_PLAN, plans.CONCRETE_PLAN]}
        customFields={[
          {
            customHTTP: false,
            notificationsFree: true,
            notificationsPaid: false,
          },
          {
            customHTTP: true,
            notificationsFree: true,
            notificationsPaid: true,
          },
          {
            customHTTP: true,
            notificationsFree: true,
            notificationsPaid: false,
          },
        ]}
        trial={[false, 14, 14]}
        rows={[
          {
            name: "Monitors",
            type: "quota",
            key: "monitors",
          },
          {
            name: "Monitoring Interval",
            type: "quota",
            key: "minMonitoringInterval",
            modifier: (value) => humanizeDuration(value * 1000, { largest: 1 }),
          },
          {
            name: "SSL Monitoring",
            type: "feature",
            key: "sslMonitoring",
          },
          {
            name: "Email, Webhooks, Slack",
            type: "custom",
            key: "notificationsFree",
          },
          {
            name: "SMS",
            type: "custom",
            key: "notificationsPaid",
          },
          {
            name: "Custom HTTP Methods, Body, Headers etc.",
            type: "custom",
            key: "customHTTP",
          },
          {
            name: "Public Status Pages",
            type: "feature",
            key: "statusPages",
          },
          {
            name: "on your own domain",
            type: "feature",
            key: "statusPagesCustomDomain",
            indentationLevel: 1,
          },
        ]}
      />
      <Link href="/pricing/features">
        <MT sx={{ textDecoration: "underline", cursor: "pointer" }}>
          View All Features
        </MT>
      </Link>
    </>
  );
}
